<template>
    <inline-form-row
        _type="b-form-select"
        :options="this.currencies"
        v-bind="$attrs"
        v-on="$listeners"
        :value="currency"
        @input="$emit('input')"
        helpText="Account Currency"
    />
</template>
<script>

import { getSetting } from '@/api/settings.api'

import InlineFormRow from '@/components/InlineFormRow'
import crudMixin from '@/api/crud'

export default {
    mixins: [crudMixin],
    props: {
        value: String
    },
    data() {
        return {
            currencies: ['CAD', 'USD', 'EUR'],
            defaultCurrency: null
        }
    },
    components: {
        InlineFormRow
    },
    computed: {
        currency() {
            if(this.value == null) {
                return this.defaultCurrency
            }
            return this.value
        }
    },
    mounted() {
        this.getDefaultCurrency()
    },
    methods: {
        getDefaultCurrency() {
            this.call(
                getSetting('currency'),
                (res) => {
                    this.defaultCurrency = res.data.setting.value
                }
            )
        }
    }
}
</script>