import httpClient from './httpClient';

const END_POINT = '/projects';

const getAllProjects = () => httpClient.get(END_POINT);

const getProject = (id) => httpClient.get(END_POINT + '/' + id);

const createProject = (project) => httpClient.post(END_POINT, { project });

const updateProject = (id, project) => httpClient.put(END_POINT + '/' + id, { project } );

const deleteProject = (id) => httpClient.delete(END_POINT + '/' + id);

const activateProject = (id) => httpClient.put(END_POINT + '/activate/' + id);

const deactivateProject = (id) => httpClient.put(END_POINT + '/deactivate/' + id);

const getTimesheets = (id) => httpClient.get(END_POINT + '/' + id + '/timesheets');

const getInvoices = (id) => httpClient.get(END_POINT + '/' + id + '/invoices');

const getTimesheetInvoiceMapping = (id) => httpClient.get(END_POINT + '/' + id + '/timesheet-invoice-mapping')

export {
    getProject,
    createProject,
    deleteProject,
    activateProject,
    deactivateProject,
    getAllProjects,
    updateProject,
    getTimesheets,
    getInvoices,
    getTimesheetInvoiceMapping
}
